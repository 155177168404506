.container-image {
    display: flex;
    justify-content: center;
    flex: 2;
    width: 100%;
}

.container-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // flex: 1;
    margin-top: 24px;
    margin-bottom: 24px;
    width: 100%;
    h2 {
        font-weight: 500;
        font-size: 36px;
        padding: 0;
        line-height: 0.8;
        margin: 0;
        text-align: center;
    }
    h6 {
        line-height: 0.8;
        color: #444;
        font-size: 16px;
        padding: 0;
        text-align: center;
    }

    @media (max-width: 600px){  
        display: none;
    }

}

.principal-container-dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}